export const getPostalCode = (place) => {
  const typesOrder = ['postal_code', 'route', 'locality', 'political'];
  console.log('Place searched at geolocation:', place);
  const displayComponent = typesOrder.map(
    (type) => place.address_components.find((component) => component.types.includes(type)),
  ).find((component) => component);
  console.log('Display component:', displayComponent);
  return displayComponent ? displayComponent.long_name : '';
};

export const formatAddress = (address) => {
  console.log(address);
  if (!address) {
    return '';
  }

  return `${address.address_line1}, ${address.city}, ${address.province} ${address.postal_code}, ${address.country}`;
};
