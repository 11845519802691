import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, useTheme } from '@mui/material';
import cameraLogo from '../../resource/images/camera-logo.svg';
import campingLogo from '../../resource/images/camping-logo.svg';
import actionCamLogo from '../../resource/images/action-cam-logo.svg';
import winterSportsLogo from '../../resource/images/winter-sports-logo.svg';
import waterSportsLogo from '../../resource/images/water-sports-logo.svg';


const useStyles = (theme) => ({
  headline: {
    width: '100%',
    textAlign: 'center',
    fontSize: '2rem',
    marginTop: 8,
    marginBottom: 8,
    [theme.breakpoints.up('md')]: {
      fontSize: '3rem',
      marginTop: 16,
    },
  },
  categoryBoxes: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    overflow : 'scroll',
  },
  categoryBox: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingY: theme.spacing(6),
    paddingX: theme.spacing(2),
    marginX: theme.spacing(0.5),
    [theme.breakpoints.up('md')]: {
      paddingY: theme.spacing(8),
      paddingX: theme.spacing(4),
      marginX: theme.spacing(2),
    },
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius,
    transition: 'transform 0.3s ease, background-color 0.3s ease',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      transform: 'scale(1.1)',
      cursor: 'pointer',
    },
  },
  logoImage: {
    width: '80%',
    height: 'auto',
  },
  categoryName: {
    width: '100%',
    textAlign: 'center',
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem',
    },
  },
});

function CategoryCards() {
  const theme = useTheme();
  const styles = useStyles(theme);
  const navigate = useNavigate();

  const redirectToProductListCategory = (category) => {
    const url = `/products?categories=${category}`;
    navigate(url);
  }

  return (
    <>
      <Typography variant="h1" sx={styles.headline}>Browse the Marketplace</Typography>

      <Box sx={styles.categoryBoxes}>
        <Box sx={styles.categoryBox} onClick={() => redirectToProductListCategory('DSLR,Mirrorless')}>
          <Box sx={styles.logoImage}>
            <img src={cameraLogo} alt="Camera Logo" style={{ width: '100%', height: '100%' }} />
          </Box>
          <Typography variant="h5" sx={styles.categoryName}>Digital Cameras</Typography>
        </Box>

        <Box sx={styles.categoryBox} onClick={() => redirectToProductListCategory('Camping')}>
          <Box sx={styles.logoImage}>
            <img src={campingLogo} alt="Camping Logo" style={{ width: '100%', height: '100%' }} />
          </Box>
          <Typography variant="h5" sx={styles.categoryName}>Camping</Typography>
        </Box>

        <Box sx={styles.categoryBox} onClick={() => redirectToProductListCategory('Action Camera')}>
          <Box sx={styles.logoImage}>
            <img src={actionCamLogo} alt="Action Camera Logo" style={{ width: '100%', height: '100%' }} />
          </Box>
          <Typography variant="h5" sx={styles.categoryName}>Action Cameras</Typography>
        </Box>

        <Box sx={styles.categoryBox} onClick={() => redirectToProductListCategory('Winter Sports')}>
          <Box sx={styles.logoImage}>
            <img src={winterSportsLogo} alt="Winter Sports Logo" style={{ width: '100%', height: '100%' }} />
          </Box>
          <Typography variant="h5" sx={styles.categoryName}>Winter Sports</Typography>
        </Box>

        <Box sx={styles.categoryBox} onClick={() => redirectToProductListCategory('Water Sports')}>
          <Box sx={styles.logoImage}>
            <img src={waterSportsLogo} alt="Water Sports Logo" style={{ width: '100%', height: '100%' }} />
          </Box>
          <Typography variant="h5" sx={styles.categoryName}>Water Sports</Typography>
        </Box>
      </Box>
    </>
  );
}

export default CategoryCards;
