import React from 'react';
import { Box, Toolbar, IconButton, Typography, useTheme } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TikTokIcon from '@mui/icons-material/SportsEsports'; // Placeholder for TikTok
import logo from '../../../resource/images/logo.svg';

const useStyles = (theme) => ({
  footerContainer: {
    backgroundColor: '#333',
    color: '#fff',
    paddingTop: 2,
    paddingBottom: 2,
    marginTop: 'auto',
  },
  footerToolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
    paddingX: 4,
    flexWrap: 'wrap', // Ensures responsiveness
  },
  leftContainer: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    paddingX: 2,
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  footerLogo: {
    height: '30px',
    cursor: 'pointer',
  },
  visionContainer: {
    flex: 1,
  },
  visionText: {
    fontSize: '0.7rem',
    color: '#ddd',
    fontStyle: 'italic',
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
  },
  rightContainer: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 1,
  },
  socialContainer: {
    display: 'flex',
  },
  icon: {
    marginRight: 1,
  },
  blogLink: {
    fontSize: '0.8rem',
    textDecoration: 'underline',
    color: '#fff',
    cursor: 'pointer',
    '&:hover': {
      color: '#ccc',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
  },
  addressText: {
    fontSize: '0.7rem',
    color: '#aaa',
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
  },
});

function Footer() {
  const theme = useTheme();
  const styles = useStyles(theme);

  const redirectTo = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Box sx={styles.footerContainer}>
      <Toolbar sx={styles.footerToolbar}>
        <Box sx={styles.leftContainer}>
          {/* Logo */}
          <Box sx={styles.logoContainer}>
            <Box
              component="img"
              src={logo}
              alt="Rentovault Logo"
              sx={styles.footerLogo}
            />
          </Box>

          {/* Vision Statement */}
          <Box sx={styles.visionContainer}>
            <Typography variant="body1" sx={styles.visionText}>
              Our mission is to create a world where everyone can freely engage in photography and outdoor activities by breaking down barriers of ownership and cost.
            </Typography>
          </Box>
        </Box>

        {/* Social Media, Blog, and Address */}
        <Box sx={styles.rightContainer}>
          <Box sx={styles.socialContainer}>
            <IconButton
              sx={styles.icon}
              onClick={() => redirectTo('https://www.instagram.com/rentovault')}
              color="inherit"
              aria-label="Instagram"
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              sx={styles.icon}
              onClick={() => redirectTo('https://www.youtube.com/@rentovault')}
              color="inherit"
              aria-label="YouTube"
            >
              <YouTubeIcon />
            </IconButton>
            <IconButton
              sx={styles.icon}
              onClick={() => redirectTo('https://www.tiktok.com/@rentovault')}
              color="inherit"
              aria-label="TikTok"
            >
              <TikTokIcon />
            </IconButton>
          </Box>
          <Typography
            variant="body1"
            sx={styles.blogLink}
            onClick={() => redirectTo('https://blog.rentovault.com')}
          >
            Our Blog
          </Typography>
          <Typography variant="body2" sx={styles.addressText}>
            150 George Street,
            <br />
            Toronto
            <br />
            ON, M5A 2M7
          </Typography>
        </Box>
      </Toolbar>
    </Box>
  );
}

export default Footer;
