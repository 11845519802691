import React from 'react';
import { Link } from 'react-router-dom';

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '10%',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
  },
  header: {
    fontSize: '8rem',
    marginBottom: '1rem',
  },
  message: {
    fontSize: '1.2rem',
    marginBottom: '2rem',
  },
  link: {
    fontSize: '1rem',
    color: '#007bff',
    textDecoration: 'none',
  },
};

function NotFoundPage() {
  return (
    <div style={styles.container}>
      <meta name="robots" content="noindex" />
      <h1 style={styles.header}>404</h1>
      <p style={styles.message}>Oops! The page you&apos;re looking for doesn&apos;t exist.</p>
      <Link to="/" style={styles.link}>
        Go Back to Home
      </Link>
    </div>
  );
}

export default NotFoundPage;
