import React from 'react';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import SearchBar from '../common/search-bar';
import Banner from '../common/banner';
import HowItWorks from '../common/how-it-works';
import styles from './styles';
import CategoryCards from './category-cards';

function Home(props) {
  const {
    searchFilter,
  } = props;

  const navigate = useNavigate();

  const redirectToProductSearch = () => {
    navigate(`/products?search=${searchFilter.searchText}`);
  };

  return (
    <div>
      <Helmet>
        <title>{`Rentovault`}</title>
        <meta name="description" content="Rentovault - Your peer-to-peer platform for renting photography and outdoor gear."></meta>
      </Helmet>
      <Grid container spacing={2} alignItems="center" sx={styles.container}>
        <Grid item xs={12} md={7}>
          <Banner />
        </Grid>
        <Grid item xs={12} md={5}>
          <SearchBar
            handleClickOnSearch={redirectToProductSearch}
            clearOnStart
          />
        </Grid>

        <CategoryCards />

        <HowItWorks />

      </Grid>
    </div>
  );
}

Home.propTypes = {
  searchFilter: PropTypes.oneOfType([PropTypes.object]),
};

Home.defaultProps = {
  searchFilter: {},
};

const mapStateToProps = (state) => ({
  searchFilter: state.product.searchFilter,
});

const enhance = compose(connect(mapStateToProps));

export default enhance(Home);
